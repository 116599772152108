<template>
  <div class="appointSuccess">
    <div class="appointSuccess-t">
      <div class="box">
        <img src="./img/success.png" alt="" />
        你的预定订单已提交成功
      </div>
    </div>
    <div class="appointSuccess-c">
      <img src="./img/success_bg.png" alt="" />
    </div>
    <div class="goOnBox">
      <div class="box" @click="toAppointIndex">继续预定</div>
    </div>
    <div class="goLookBox">
      <div class="box" @click="toLook">去查看</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "appointmentSuccess",
  data() {
    return {};
  },
  created() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    defOrgId() {
      return this.$store.state.defOrgId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  mounted() {},
  methods: {
    toLook() {
      this.$router.replace({
        name: "myAppointList",
      });
    },
    toAppointIndex() {
      this.$router.replace({
        name: "venueAppoint",
        query: {
          userId: this.userId,
          defOrgId: this.defOrgId,
          houseId: this.houseId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.appointSuccess {
  background: #fafafa;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 210px;
  box-sizing: border-box;

  .appointSuccess-t {
    width: 100%;

    .box {
      margin: 0 150px;
      font-size: 36px;
      font-weight: 600;
      color: #5e5aff;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        margin-right: 14px;
      }
    }
  }

  .appointSuccess-c {
    width: 100%;
    height: 322px;
    margin-top: 124px;
    margin-bottom: 182px;
    img {
      display: block;
      width: 568px;
      margin: 0 auto;
    }
  }

  .goOnBox {
    width: 100%;
    height: 66px;
    padding: 0 100px;
    box-sizing: border-box;

    .box {
      text-align: center;
      height: 66px;
      line-height: 66px;
      background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
      border-radius: 10px;
      font-size: 30px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .goLookBox {
    width: 100%;
    height: 66px;
    padding: 0 100px;
    box-sizing: border-box;
    margin-top: 30px;

    .box {
      box-sizing: border-box;
      text-align: center;
      height: 66px;
      line-height: 66px;
      font-size: 30px;
      font-weight: 600;
      color: #5e5aff;
      border: 2px solid;
      // border-image: linear-gradient(180deg, rgba(149, 145, 255, 1), rgba(96, 92, 255, 1)) 2 2;
      border-radius: 10px !important;
    }
  }
}
</style>
